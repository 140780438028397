import { format, parseISO } from "date-fns";
import { fi } from "date-fns/locale";
import _ from "lodash";

export function devEnv(): boolean {
  return process.env.NODE_ENV === "development";
}

export function prodEnv(): boolean {
  return !devEnv();
}

const eurFormatter = new Intl.NumberFormat("fi-FI", {
  style: "currency",
  currency: "EUR",
});

export function formatPrice(
  cents: number | undefined | null,
  emptyIndicator: string = ""
): string {
  if (_.isNumber(cents)) {
    return eurFormatter.format(cents / 100);
  }
  return emptyIndicator;
}

export function formatDate(date: Date | string, formatStr = "P") {
  if (typeof date === "string") {
    date = parseISO(date);
  }
  return format(date, formatStr, { locale: fi });
}

export function capitalizeFirst(s: string): string {
  if (s.length === 0) {
    return s;
  }
  if (s.length === 1) {
    return s.toUpperCase();
  }
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export function randStr(length: number): string {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
}

export function classNames(
  ...args: Array<
    | false
    | undefined
    | null
    | string
    | Array<string>
    | { [key: string]: boolean | undefined | null }
  >
): string {
  var classes: string[] = [];

  for (var i = 0; i < args.length; i++) {
    var arg = args[i];
    if (!arg) continue;

    if (typeof arg === "string") {
      classes.push(arg);
    } else if (Array.isArray(arg)) {
      classes.push(...arg);
    } else if (typeof arg === "object") {
      if (arg.toString === Object.prototype.toString) {
        for (var [key, value] of Object.entries(arg)) {
          if (value) {
            classes.push(key);
          }
        }
      } else {
        classes.push(arg.toString());
      }
    }
  }

  return classes.join(" ");
}

export function slugify(str: string): string {
  str = str.replace(/^\s+|\s+$/g, "");

  // Make the string lowercase
  str = str.toLowerCase();

  // Remove accents, swap ñ for n, etc
  var from =
    "ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/_,:;";
  var to =
    "AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa------";
  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }

  // Remove invalid chars
  str = str
    .replace(/[^a-z0-9 -]/g, "")
    // Collapse whitespace and replace by -
    .replace(/\s+/g, "-")
    // Collapse dashes
    .replace(/-+/g, "-");

  return str;
}

const cityNameTaivutusMap: { [key: string]: string } = {
  Äänekoski: "Äänekosken",
  Espoo: "Espoon",
  Forssa: "Forssan",
  Haapajärvi: "Haapajärven",
  Haapavesi: "Haapaveden",
  Haukipudas: "Haukiputaan",
  Heinola: "Heinolan",
  Helsinki: "Helsingin",
  Hollola: "Hollolan",
  Hyvinkää: "Hyvinkään",
  Hämeenlinna: "Hämeenlinnan",
  Iisalmi: "Iisalmen",
  Ikaalinen: "Ikaalisten",
  Imatra: "Imatran",
  Inkeroinen: "Inkeroisen",
  Joensuu: "Joensuun",
  Joutseno: "Joutsenon",
  Jyväskylä: "Jyväskylän",
  Järvenpää: "Järvenpään",
  Kaarina: "Kaarinan",
  Kajaani: "Kajaanin",
  Kangasala: "Kangasalan",
  Kankaanpää: "Kankaanpään",
  Kaskinen: "Kaskisten",
  Kemi: "Kemin",
  Kemiönsaari: "Kemiönsaaren",
  Kempele: "Kempeleen",
  Kerava: "Keravan",
  Keuruu: "Keuruun",
  Kiiminki: "Kiimingin",
  Kitee: "Kiteen",
  Klaukkala: "Klaukkalan",
  Kokemäki: "Kokemäen",
  Kokkola: "Kokkolan",
  Kotka: "Kotkan",
  Kouvola: "Kouvolan",
  Kristiinankaupunki: "Kristiinankaupungin",
  Kuhmo: "Kuhmon",
  Kuhmoinen: "Kuhmoisten",
  Kuopio: "Kuopion",
  Kuusamo: "Kuusamon",
  Kuusankoski: "Kuusankosken",
  Lahti: "Lahden",
  Laihia: "Laihian",
  Lapua: "Lapuan",
  Lappeenranta: "Lappeenrannan",
  Lempäälä: "Lempäälän",
  Lieksa: "Lieksan",
  Lieto: "Liedon",
  Lohja: "Lohjan",
  Loimaa: "Loimaan",
  Maarianhamina: "Maarianhaminan",
  Mikkeli: "Mikkelin",
  Muurame: "Muuramen",
  Mäntsälä: "Mäntsälän",
  Naantali: "Naantalin",
  Nivala: "Nivalan",
  Nilsiä: "Nilsiän",
  Nokia: "Nokian",
  Nurmes: "Nurmeksen",
  Nurmijärvi: "Nurmijärven",
  Oulainen: "Oulaisen",
  Oulu: "Oulun",
  Oulunsalo: "Oulunsalon",
  Orimattila: "Orimattilan",
  Orivesi: "Oriveden",
  Paimio: "Paimion",
  Parainen: "Paraisten",
  "Pedersören kunta": "Pedersören kunnan",
  Pietarsaari: "Pietarsaaren",
  Pieksämäki: "Pieksämäen",
  Pirkkala: "Pirkkalan",
  Pori: "Porin",
  Porvoo: "Porvoon",
  Raahe: "Raahen",
  Raisio: "Raision",
  Rantasalmi: "Rantasalmen",
  Rauma: "Rauman",
  Riihimäki: "Riihimäen",
  Rovaniemi: "Rovaniemen",
  Saarijärvi: "Saarijärven",
  Salo: "Salon",
  Savonlinna: "Savonlinnan",
  Seinäjoki: "Seinäjoen",
  Siilinjärvi: "Siilinjärven",
  Sipoo: "Sipoon",
  Sodankylä: "Sodankylän",
  Somero: "Somerolla",
  Sotkamo: "Sotkamon",
  Suonenjoki: "Suonenjoen",
  Tampere: "Tampereen",
  Tornio: "Tornion",
  Turku: "Turun",
  Tuusula: "Tuusulan",
  Uusikaarlepyy: "Uusikaarlepyyn",
  Uusikaupunki: "Uudenkaupungin",
  Vaajakoski: "Vaajakosken",
  Vaasa: "Vaasan",
  Valkeakoski: "Valkeakosken",
  Vantaa: "Vantaan",
  Varkaus: "Varkauden",
  Veteli: "Vetelin",
  Vihti: "Vihdin",
  Viitasaari: "Viitasaaren",
  Virrat: "Virtain",
  Ylivieska: "Ylivieskan",
  Ylöjärvi: "Ylöjärven",
  Ulvila: "Ulvilan",
};

export function taivutaToPaikkakunnan(cityName: string): string {
  if (cityName in cityNameTaivutusMap) {
    return cityNameTaivutusMap[cityName];
  } else {
    return "paikkakunnan " + cityName;
  }
}
